import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { HttpStateService, HttpProgressState } from '../services/http-state.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { OverlayService } from '../services/overlay.service';
import { Router } from '@angular/router';
/**
 * Show an Error if there occures one
 */
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  /**
   * constructor
   * @param httpStateService 
   * @param overlay 
   * @param router 
   */
  constructor(
    private httpStateService: HttpStateService,
    private overlay: OverlayService,
    private router: Router) {

  }
  /**
   * Show the Error via the OverlayService (openSnackBar)
   * @param request 
   * @param next 
   * @returns 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpStateService.state.next({
      url: request.url,
      state: HttpProgressState.start
    });

    let element = document.getElementById('Sidenav')
    if (element) {
      element.style.overflow = 'hidden';
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        }
        else {
          if (error?.error?.message != undefined) {
            this.overlay.openSnackBar(error.error.message)
          } else {
            this.overlay.openSnackBar("Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es erneut.")
          }
        }
        return throwError(() => error);
      }), finalize(() => {
        let element = document.getElementById('Sidenav')
        if (element) {
          element.style.overflow = 'auto';
        }
        this.httpStateService.state.next({
          url: request.url,
          state: HttpProgressState.end
        });
      }
      ))
  }
}