import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { AppMaterialModule } from './helpers/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpsRequestInterceptorJwt } from './helpers/https-request.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { InterceptorService } from './helpers/loading.interceptor';
import { SharedComponentsModule } from './components/shared-components.module';
import { DatePipe } from '@angular/common';
import { TruncateModule } from './pipes/truncate.module'
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QuillModule } from 'ngx-quill';
import { TranslateStateModule } from './pipes/translate-state.module'
import { ChartsModule } from "@carbon/charts-angular";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import 'moment/locale/de';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';
import { getGermanPaginatorIntl } from './germanRangeLabel';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedComponentsModule,
        TruncateModule,
        TranslateStateModule,
        NgxDropzoneModule,
        QuillModule.forRoot(),
        ChartsModule,
        SharedPipesModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
           enabled: !isDevMode(),           
           registrationStrategy: 'registerImmediately' 
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptorJwt,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: MatPaginatorIntl,
            useValue: getGermanPaginatorIntl()
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        DatePipe,
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
