import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HttpsRequestInterceptorJwt implements HttpInterceptor {
  /**
   * constructor
   * @param authenticationService 
   * @param environmentService 
   */
  constructor(
    private authenticationService: AuthenticationService,
    private environmentService: EnvironmentService) { }
  /**
   * Add a Token to the Headers
   * @param request 
   * @param next 
   * @returns 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    if (this.isValidRequestForInterceptor(request.url, this.environmentService.getClientId())) {
      return this.authenticationService.getcurrentUserObs().pipe(
        switchMap((token: any) => {
          if (request.url.includes('https://mcp.swaas.de')) {
            let modifiedRequest = request.clone({
              setHeaders: {
                "Authorization": `Bearer ${token?.token}`
              }
            });
            return next.handle(modifiedRequest);
          } else {
            let modifiedRequest = request.clone({
              setHeaders: {
                "X-OIDC-Token": `Bearer ${token?.token}`
              }
            });
            return next.handle(modifiedRequest);
          }
        })
      )
    } else {
      return next.handle(request);
    }
  }
  /**
   * Check if the the Token should be added
   * @param requestUrl 
   * @param clientID 
   * @returns 
   */
  private isValidRequestForInterceptor(requestUrl: string, clientID): boolean {
    if (requestUrl.includes("https://pixabay.com/api?key=24605956-982d3aa6be88eb55a8816bbce") || (requestUrl.includes(`https://mcp.swaas.de/client/${clientID}/property`)) || requestUrl.includes('authorize') || (requestUrl.includes(`https://mcp.swaas.de/client/${clientID}/property`))) {
      return false
    } else {
      return true;
    }

  }
}
