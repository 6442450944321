import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/**
 * The State of the HTTP-Request
 */
export interface IHttpState {
  url: string;
  state: HttpProgressState;
}
/**
 * The State of the HTTP-Request
 */
export enum HttpProgressState {
  start,
  end
}

@Injectable({
  providedIn: 'root'
})
export class HttpStateService {
  /**
   * The State as a BehaviorSubject
   */
  public state = new BehaviorSubject<IHttpState>({} as IHttpState);
  /**
   * The constructor for the HttpStateService
   */
  constructor() { }
}
