import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
/***
 * Check of the User is an Admin
 */
@Injectable({
  providedIn: 'root'
})
export class RoleAdminGuard  {
  /**
   * constructor
   * @param router 
   * @param authenticationService 
   */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  /**
   * Check of the User is an Admin
   * @param route 
   * @param state 
   * @returns 
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.decodedToken$.value;
    if (currentUser.role == "ROLE_ADMIN") {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/']);
    return false;
  }
}
