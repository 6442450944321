import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { OverlayService } from '../services/overlay.service';
/**
 * Show the Session abgelaufen overlay
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * constructor
   * @param authenticationService 
   * @param overlayService 
   */
  constructor(
    private authenticationService: AuthenticationService,
    private overlayService: OverlayService) { }
/**
 * Show the Session abgelaufen when the Token Expires (401)
 * @param request 
 * @param next 
 * @returns 
 */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err:HttpErrorResponse) => {
        if (err.status == 401) {
          if (err.error.message != '') {
          //  this.authenticationService.logout();
            this.overlayService.openSnackBar(err.error.message, "error", 5000)
          } else {
            // auto logout if 401 response returned from api
          //  this.authenticationService.logout();
            this.overlayService.openSnackBar("Session abgelaufen", "error", 5000)
          }
        }
        const error = err?.error?.message || err.statusText;
        return throwError(() => err);
      })
    );
  }
}
