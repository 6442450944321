import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoadingService } from './services/loading.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * is the Page Loading
   */
  showLoading: boolean = true;
  /**
   * constructor
   * @param router 
   */
  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private _snackBar: MatSnackBar) {

    /**
     * Check for PWA Updates
     */
    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          let snackBarRef = this._snackBar.open("Neue Version verfügbar", "Update", { verticalPosition: 'top', horizontalPosition: 'center', politeness: 'off' });
          snackBarRef.onAction().subscribe(() => {
            window.location.reload();
          });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }
  /**
   * ngOnInit
  */
  ngOnInit() {
    caches.open('ngsw:/:db:control').then((cache) => {
      return cache.match('/latest').then((resLts) => {
        return resLts?.json().then((bodyLts) => {
          return cache.match('/manifests').then((resManifests) => {
            return resManifests?.json().then((manifests) => {
              console.log({
                hash: bodyLts.latest,
                timestamp: new Date(manifests[bodyLts.latest].timestamp).toLocaleDateString() + " _ " + new Date(manifests[bodyLts.latest].timestamp).toLocaleTimeString(),
              })
            });
          });
        });
      });
    })
  }
}
